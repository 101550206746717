import React, { Component } from 'react';
import {
  AreaChart ,Area , Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  } from 'recharts';


const graphWidth = 500;
const graphHeight = 300; 

export class VipLineChart extends Component {
    constructor(props){
      super(props);
    }


    render() {
        const zoom = (this.props.containerWidth/graphWidth);
        let conatinerStyle = {
          width:"100%" ,
          zoom: zoom+'',
          justifyContent: 'center',
          display: 'flex'
        }
        return (
          <div style={conatinerStyle} >
          <AreaChart   
            width={graphWidth}
            height={graphHeight}
            data={this.props.data}
            margin={{
              top: 5, right: 0, left: 0, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" />
            {/* <Legend /> */}
            <YAxis />
            <Tooltip />
            {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
            <Area  type="monotone" dataKey="y" stroke="#82ca9d"  activeDot={{ r: 8 }}/>
          </AreaChart >
          </div>
        );
      }
}

