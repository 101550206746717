import React, { Component } from 'react';
import { useQueryParam, NumberParam, StringParam } from "use-query-params"
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { VipLineChart } from '../Graphs'
  
const data0 = [
  {x: 'Page A', uv: 4000, pv: 2400, y: 2400,},
  {x: 'Page B', uv: 3000, pv: 1398, y: 2210,},
  {x: 'Page C', uv: 2000, pv: 9800, y: 2290,},
  {x: 'Page D', uv: 2780, pv: 3908, y: 2000,},
  {x: 'Page E', uv: 1890, pv: 4800, y: 2181,},
  {x: 'Page F', uv: 2390, pv: 3800, y: 2500,},
  {x: 'Page G', uv: 3490, pv: 4300, y: 2100,},
];
const data1 = [
  {x: 'Page A', y: 4000,},
  {x: 'Page B', y: 3000,},
  {x: 'Page C', y: 2000,},
  {x: 'Page D', y: 2780,},
  {x: 'Page E', y: 1890,},
  {x: 'Page F', y: 2390,},
  {x: 'Page G', y: 3490,},
];

const data2 = [
  {x: 'Page A', uv: 4000, y: 2400, },
  {x: 'Page B', uv: 3000, y: 1398, },
  {x: 'Page C', uv: 2000, y: 9800, },
  {x: 'Page D', uv: 2780, y: 3908, },
  {x: 'Page E', uv: 1890, y: 4800, },
  {x: 'Page F', uv: 2390, y: 3800, },
  {x: 'Page G', uv: 3490, y: 4300, },
];



class DeviceBase extends Component {
  _initFirebase = false;
  stateRef = null;
  state = {
    text: '',
    loading: true,
    device: null,
    deviceState: {},
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    isControlPage: false,
    telemetryList: null,
  };
  constructor(props) {
    super(props);
  }

  updateSize = ()=>{ 
    // console.log(window.innerWidth + '-'+window.innerHeight)
    this.setState({    
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    })
  }

  firebaseInit = () => {
    if (this.props.firebase && this.props.id && !this._initFirebase ) {
      this._initFirebase = true;
      this.listenForDevice();
    }
  };

  componentDidMount() {   
    this.updateSize();
    window.addEventListener('resize', this.updateSize);
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.updateSize);
    if(this.stateRef)  this.stateRef.off()
  }

  listenForDevice = () => {

    this.setState({ loading: true });
    // console.log(this.props.id);
    const deviceRef = this.props.firebase.device(this.props.id);
    deviceRef
        .get()
        .then(snapshot => {
            const deviceObject = snapshot.data();
            if (deviceObject) {
                let device = {
                    ...deviceObject,
                    key:this.props.id,
                    uid: this.props.authUser.uid,
                };
                // this.props.firebase.getTelemetryList(this.props.id).then(
                //   result => this.setState({telemetryList : result.data.collections})
                // ).catch((error) => {
                //   // Getting the Error details.
                //   var code = error.code;
                //   var message = error.message;
                //   var details = error.details;
                //   // ...
                // });

                
                // deviceRef.getCollections()
                this.setState({device, loading: false });
            } 
            else this.setState({ device: null, loading: false });
        });
    this.stateRef = this.props.firebase.deviceStates(this.props.id)
    this.stateRef.on('value', (snapshot) => {
      // console.log(snapshot)
      const data = snapshot.val();
      this.setState({deviceState:data});
      // console.log("cloud state update",data)
    });
  };
  


  render() {
    const { text, device, loading,windowWidth,windowHeight,isControlPage,telemetryList,deviceState } = this.state;
    const { firebase , authUser, id } = this.props;
    // console.log(device);
    let chartsList = [];
    if (telemetryList){
      chartsList = telemetryList.map(teleId => 
        <div key={teleId} className="card card-style">
          <div className="content mt-2 mb-2">
              <h3 className="text-center mb-3">{teleId}</h3>
              <VipLineChart containerWidth={this.state.windowWidth-80} data={data0}/>
          </div>
        </div>
      )
    }
    return (
          <div>
            {loading && <div id="preloader"><div className="spinner-border color-highlight" role="status"></div></div>}
            {(!loading && device) && (
              <div>
                <div className="card card-style">
                  <div className={deviceState.online ? "pt-3 pb-2 bg-highlight" : "pt-3 pb-2 bg-dark1-dark" }>
                    {/* <div className="card-overlay bg-20 card-blur-image" style={{zIndex: '-1'}}></div>
                    <div className="card-overlay bg-black opacity-50" style={{zIndex: '-1'}} ></div> */}
                    <h1 className="color-white text-center mb-0" > {device.name} {deviceState.online ? "(online)" : "(offline)" }</h1>
                    <p className="color-white text-center opacity-80 mt-n1 mb-0">{device.model}</p>
                  </div>
                  <div className="tab-controls tabs-round tab-animated tabs-large tabs-bottom-rounded shadow-xl">
                        <a onClick={()=> this.setState({isControlPage:false})} 
                          className={"font-weight-bold " + (!isControlPage ? "bg-sky-light color-white" : "bg-white color-black")} 
                          style={{width: "50%"}}>
                            <i className="fa fa-chart-bar fa-2x align-middle "></i> &nbsp; Data
                        </a>
                        <a onClick={()=> this.setState({isControlPage:true})} 
                          className={"font-weight-bold " + (isControlPage ? "bg-sky-light color-white" : "bg-white color-black")} 
                          style={{width: "50%"}}>
                            <i className="fa fa-cogs fa-2x align-middle"></i> &nbsp; Control
                        </a>
                    </div>
                </div>
                {(!isControlPage) && chartsList}
                {(isControlPage)  && (
                 <div className="card card-style">
                  {[0,1,2,3].map(p=>(
                    <div className="content mb-2" key={p}>
                      <h1>Port: {p+1} {deviceState.online && deviceState.state && deviceState.state.ports ? (["","⚡","🔛"][deviceState.state.ports[p]]) : "" }</h1>
                      <button class="btn btn-s rounded-xl font-600 shadow-s bg-red2-dark mr-4 " type="button" onClick={()=> firebase.setSwitch(id,p,0)}>
                        Off
                      </button>
                      <button class="btn btn-s rounded-xl font-600 shadow-s bg-green2-dark mr-4" type="button" onClick={()=> firebase.setSwitch(id,p,1)}>
                        On (Static)
                      </button>
                      <button class="btn btn-s rounded-xl font-600 shadow-s bg-blue2-dark " type="button" onClick={()=> firebase.setSwitch(id,p,2,[4.5,10.5])}>
                        On (Toggle)
                      </button>
                      <br/>
                    </div>
                  ))}

                 </div> 
                )}
              </div>
            )}     
            {(!loading && !device) && 'The device not found !'}
          </div>
    );
  }
}

const Device  = (props) =>  {
    const [id, setId] = useQueryParam("id", StringParam);
    return (
      <AuthUserContext.Consumer>
        { (id) && (authUser => <DeviceBase authUser={authUser} firebase={props.firebase} id={id}/> ) }  
      </AuthUserContext.Consumer>
    );
}

export default withFirebase(Device);
