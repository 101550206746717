import React from "react";
import Layout from '../components/layout';
import Device from '../components/Device'
import { compose } from 'recompose';
import {
  withAuthorization,
  withEmailVerification,
} from '../components/Session';

const DevicePageBase = () => (
    <div className="page-content header-clear-medium" >
        <Device/>
    </div>
);

const condition = authUser => !!authUser;

const DevicePage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(DevicePageBase);

export default () => {
    return  (
    <Layout>
        <DevicePage/>
    </Layout>)
};